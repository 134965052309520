<template>
  <div class="container-bg-image">
    <div class="content-image">
      <img
        src="@/assets/images/fixed-image-login.png"
        alt="proplat-image.png"
      >
    </div>
    <img
      class="image-float"
      src="@/assets/images/float-image-login.png"
      alt="proplat-image.png"
    >
  </div>
</template>

<script>
export default {
  name: "SigninBackgroundImageLayout",
  data() {
    return {
      //VARIABLES
    };
  },
};
</script>

<style scoped>
.container-bg-image {
  width: 100%;
  /* position: relative; */
}

.image-float {
  position: absolute;
  width: 45%;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.content-image {
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 0px;
  }
}
</style>